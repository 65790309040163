// -------------------------
// Pagination from Server
// -------------------------

Vue.component('pagination-server', {
    
    replace: true,
    
    template: '#pagination-template',
    
    ready: function ()
    {
        
    },
    
    data: function ()
    {
        return {

            // itemsPerPage: (from laravel pagination), 
            offsetIndex : 0, // Pagination pages index
            sitesInPagination : 4, // pages per site from current page
        }
    },
    
    computed : 
    {
        pagination: function () {

            return this.$parent.pagination; // get from parent element 
        }, 

        totalPages: function() 
        {
            return this.pagination.totalpages;
        },  
        currentPage: function ()
        {
            // return this.offsetIndex + 1 ; 
            return this.pagination.curpage; 
        }
    }, 
    
    methods :
    {
        resetPaginationIndex : function () {

            this.offsetIndex = 0;
        }, 

        paginateHandler : function()
        {
            this.$parent.page = this.offsetIndex + 1; 
            console.log('page index: ' + this.offsetIndex );
        },
        
        setPage: function(offsetIndex)
        {
            this.offsetIndex = offsetIndex ; 
            this.paginateHandler() ;             
        }, 
        
        prevPage: function()
        {
            if(this.offsetIndex > 0)
            {
                this.offsetIndex -- ; 
                this.paginateHandler() ; 
            }  
        }, 
        
        nextPage: function()
        {
            if(this.offsetIndex < this.totalPages - 1)
            {
                this.offsetIndex ++ ; 
                this.paginateHandler() ; 
            }
        }
        
    }
});