// ------------------------------------------------------
// attachments Component 
// ------------------------------------------------------

Vue.component('attachments-component',
{
    replace: true,

    props: ['model', 'modelid', 'upload', 'acceptedfiles'], 
    
    template: '#attachments-template',
    
    data : function()
    {
        return {
            items : null ,
            searchValue : '',

        }
    },
    
    ready: function()
    {
        var self = this ; 

        Dropzone.options.uploader = {
            
            url: this.upload, 
            acceptedFiles: this.acceptedfiles,
            dictDefaultMessage: '<div><i class="fa fa-lg fa-plus-circle margin-right-15" aria-hidden="true"></i> Kliknij aby wybrać lub upuść pliki tutaj!</div>',

            init: function()
            {
                this.on("queuecomplete", function(file) { 
                    
                    self.getAjaxCall('');
                    app.viewMessage({
                        'message_type':  'info', 
                        'message_title': 'Info', 
                        'message' 	:    'Ukończono wgrywanie',
                        'timeout':       2000
                    }) ;

                });
            }
        };
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
           this.getAjaxCall();
        }, 
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            // Ajax Call  
            this.$http.get( host + '/'+ this.model +'/'+ this.modelid +'/attachments?q=' + this.searchValue )
            
            .success(function (data, status, request) 
            {    
                self.items = data;
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        }, 
    }

});

// ------------------------------------------------------
// attachments single file Component
// ------------------------------------------------------

Vue.component('attachments-single',
{
    
    template: '#attachments-single-template' ,
    
    ready: function()
    {
        this.name_raw = this.item.name ; 
    },
    
    data : function()
    {
        return {
            item: { edit_mode : false } , 
            name_raw : null , 
        }
    },

    computed: {

        name_error : function()
        {
            if( this.item.name.length && this.item.name != 'null' )
                return false ; 
            else    
                return true ; 
        }, 
        
        url : function()
        {
            return app.get_thumb (host_storage + '/' + this.item.path, 260) ; 
        },

        is_image: function()
        {
            var ext = this.item.path.substring(this.item.path.lastIndexOf('.')+1) ; 

            if( ext == 'jpg' || ext == 'jpeg' || ext == 'png' )
            {
                return true ; 
            }   
            else
            {
                return false ; 
            }
        }, 

        created: function()
        {
            return moment(this.item.created_at).fromNow() ; 
        }, 
        
        updated: function()
        {
            return moment(this.item.updated_at).fromNow() ; 
        }

    }, 
    
    methods : 
    { 
        editMode: function(item)
        {
            item.edit_mode = !item.edit_mode ; 

            if(!item.edit_mode){
                item.name = this.name_raw ; 
            }
        },

        update: function()
        {
            // validation 
            if(!this.validateRequest())
                return ; 

            var self = this ; 
        
            app_v.$.preloader.show()  ; 

            this.$http.put( host + '/attachments/' + this.item.id , { name: this.item.name, _token: csrf_token } )
            
            .success(function (data, status, request) 
            {    
                app_v.$.preloader.hide()  ; 
                
                // set edit mode to off 
                self.item.edit_mode = false ; 

                // set new Raw data 
                self.name_raw = self.item.name; 

                app.viewMessage({
                    'message_type'  : 'success', 
                    'message_title' : 'Success', 
                    'message' 	    : 'Plik zaktualizowany',
                    'timeout'       : 2200
                }) ;
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        },

        setThumbnail: function ()
        {
            var self = this ; 

            app_v.$.preloader.show()  ; 

            this.$http.post( host + '/' + this.$parent.model + '/' + this.$parent.modelid + '/attachments/' + this.item.id + '/set_thumbnail' , { _token: csrf_token } )
            
            .success(function (data, status, request) 
            {    
                app_v.$.preloader.hide()  ; 

                self.$parent.getAjaxCall('') ; 

                app.viewMessage({
                    'message_type'  : 'success', 
                    'message_title' : 'Success', 
                    'message' 	    : 'Obraz wyróżniający został ustawiony',
                    'timeout'       : 2200
                }) ;
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })

            
        },

        delete: function( item )
        {
            var self = this ; 

            app_v.$.confirmation.show('Potwierdznie usunięcia', 'Czy potwierdzasz usunięcia załącznika ('+this.item.name+')' , 'Tak usuń', 'danger') ;
            $('#confirmSuccess').off('click').on('click', function() {

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  

                self.$http.delete( host + '/attachments/' + self.item.id , { _token: csrf_token } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.$parent.getAjaxCall('') ; 

                    app.viewMessage({
                        'message_type':  'success', 
                        'message_title': 'Powodzenie', 
                        'message' 	:    'Poprawnie usunięto załącznik',
                        'timeout':       2000
                    }) ;
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;
                    
                })
            });     
        }, 

        validateRequest : function()
        {
            if ( this.name_error )
            {
                app.viewMessage({
                    'message_type'  : 'warning', 
                    'message_title' : 'Niepowodzenie', 
                    'message' 	    : 'Należy podać nazwę załącznika',
                    'timeout'       : 1800
                }) ;
                return false ; 
            }
            else
            {
                return true ; 
            }
        }
    }

});