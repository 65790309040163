// -----------------------------------------
// Registration Component
// -----------------------------------------

Vue.component('registrations', {

    replace: true,  

    template: '#registration-template',
    
    data: function () 
    {    
        return {
            searchValue : '' , 
            registrationType : null,
            registeredModel : '', 
            registeredModelProducts : [], 

            serials : [] , 
            suggestions : null , 
        }
    }, 

    ready: function()
    {
        var self = this ; 

        this.suggestions = new Bloodhound({
            
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('model'), 
            queryTokenizer: Bloodhound.tokenizers.whitespace,

            remote: 
            {
                url: host + '/products?q=' +   self.searchValue ,    

                replace: function (url, query) 
                {   
                    var model = app_v.$.registrationComponent.registrationType  ;
                    
                    if ( model == 'products' )  
                        url = host +'/products?q=' + self.searchValue ;
                    
                    if ( model == 'sets' )  
                        url = host +'/sets?q=' + self.searchValue ;
                    
                    return url;
                },   
            }
        });

        $('.typeahead').typeahead({
            
            hint: true,
            highlight: false,
            minLength: 1,
            
            classNames: {
                input: 'Typeahead-input',
                hint:  'Typeahead-hint',
                selectable: 'Typeahead-selectable'
            }, 
        },
        {
            name: 'suggestions',
            source: self.suggestions, 

            // displayKey: ['model'],
            display: 'model',

            limit: 9,
            
            templates: {
                empty: [
                    '<div class="empty-message">',
                        '<i class="fa fa-minus-circle text-danger" aria-hidden="true"></i> Nie znaleziono produktów ...',
                    '</div>'
                ].join('\n'),

                suggestion: function(data)
                {
                    var templ = '<div class="text-left">' ;
                        
                    if(data.thumbnail){
                        templ += '<div class="image" style="background-image: url('+ app.get_thumb(host_storage +'/'+ data.thumbnail.path, 180) +')"></div>' ;
                    }

                    templ += '<span class="model">'+data.model+'</span></div>'; 

                    return templ ; 
                }
            }
        })
        .on('typeahead:asyncrequest', function() 
        {
            $('.Typeahead-spinner').show();
        })
        .on('typeahead:asynccancel typeahead:asyncreceive', function() 
        {
            $('.Typeahead-spinner').hide();
        })
        .on('typeahead:select', function(obj, datum, name)
        {
            self.clearSerialsList(); 

            self.registeredModel = datum.model ;
            
            if( self.registrationType == 'sets'){
                self.createListOfSerialInputs(datum.products) ;  
            }else{
                self.createListOfSerialInputs(datum) ; 
            }
        })
    }, 

    computed: {
        formatedSerials: function()
        {
            return JSON.stringify( this.serials ) ; 
        }
    },
    
    methods : {
        
        setRegistrationType: function(type)
        {
            this.registrationType = type ; 
            
            // Set focus
            setTimeout(function(){
                $('#search-field').focus() ; 
            },1);
            
        },

        createListOfSerialInputs: function( data )
        {
            var self = this ; 

            if(_.isArray(data)){
                this.registeredModelProducts = data ; 
            }else{
                this.registeredModelProducts.push(data); 
            }

            // create serials array  
        
            _.forEach(this.registeredModelProducts, function(product) {
                self.serials.push({
                    'model':  product.model, 
                    'serial_number': '',
                    'purchase_date': '',
                    'warranty': product.warranty 
                });
            });

            // Mask fields

            $('.date-mask').mask('y000-m0-d0', { 
                placeholder: "YYYY-MM-DD",
                'translation': {
                    y: {pattern: /[1-2]/},
                    m: {pattern: /[0-1]/},
                    d: {pattern: /[1-3]/}
                }
            });

            // Set Date Picker on fields 
            
            setTimeout(function(){
                $('.datepicker').datepicker({
                    format: "yyyy-mm-dd",
                    autoclose: true
                }) ;
            },1);
            
        },

        clearRegistrationType: function()
        {
            this.registrationType = null ; 
            this.searchValue = '' ; 
            this.suggestions.clear();
            $('.typeahead').typeahead('val', '');

            this.clearSerialsList() ; 
        },

        clearSerialsList: function(){
            
            this.registeredModel = null ;
            this.registeredModelProducts = [] ; 
            this.serials = [] ; 
        } 
    }
}); 




// -----------------------------------------
// Registration List Component
// -----------------------------------------

Vue.component('registrations-list-component',
{
    replace: true,

    props : ['type'] , 
    
    template: '#registrations-list-template',
    
    data : function()
    {
        return {
            items : null ,
            // itemsPaginate : null , 
            searchValue : '', 
            searchType : '', 

            page: 1,
            pagination: {
                perpage: null, 
                curpage: null,
                totalpages: null, 
                from: null, 
                to: null, 
                total: null,
            },
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
            this.page = 1;
            this.getAjaxCall() ;
            this.$.paginationComponent.resetPaginationIndex()
        }, 

        searchType: function () 
        {
            this.page = 1;
            this.getAjaxCall() ;
            this.$.paginationComponent.resetPaginationIndex()
        }, 

        page: function () 
        {
            this.getAjaxCall() ;
        }, 
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 

            var address = '' ; 
            if(this.type == 'all')
                address = '/registrations' ; 
            else if (this.type == 'user')
                address = '/my-registrations' ; 
            
            // Ajax Call  
            this.$http.get( host + address + '?q=' + this.searchValue + '&type=' + this.searchType + '&page=' + this.page)
            
            .success(function (data, status, request) 
            {    
                self.items = data.data;
                self.pagination.perpage = data.per_page; 
                self.pagination.curpage = data.current_page; 
                self.pagination.totalpages = data.last_page; 
                self.pagination.from = data.from; 
                self.pagination.to = data.to; 
                self.pagination.total = data.total;

                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        },

        resetFilters: function () {

            this.searchValue = ''; 
            this.searchType = '';
        }
    }

});

// ------------------------------------------------------
// Item Row Component
// ------------------------------------------------------

Vue.component('registration-itemrow-component',
{
    
    template: '#registration-itemrow-template' ,
    
    ready: function()
    {

    },
    
    data : function()
    {
        return {
            item : null , 
            type : this.$parent.type , 
        }
    },
    
    computed: {

        created: function(){
            return moment(this.item.created_at).format('Do MMM YY')  ; 
        }

    }, 
    
    methods : 
    { 
        deleteItem: function()
        {
            self = this ; 

            app_v.$.confirmation.show('Usunięcie Rejestracji', 'Czy potwierdzasz usunięcie rejestracji produktu: <br/><b>' + this.item.model + '</b><br/>Powiązane numery seryjne zostaną usunięte z systemu.', 'Usuń', 'danger') ;
            
            $('#confirmSuccess').off('click').on('click', function() {

                console.log(self.item) ;

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  

                self.$http.delete( host + '/registrations/' + self.item.id , { _token: csrf_token } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.$parent.getAjaxCall('') ; 

                    app.viewMessage({
                        'message_type':  'success', 
                        'message_title': 'Success', 
                        'message' 	:    'Rejestracja produktu zostala poprawnie usunięta',
                        'timeout':       2000
                    }) ;
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;
                    
                })
            }); 
        }
    }

});