
// ------------------------------------------------------
// attachments Component 
// ------------------------------------------------------

Vue.component('warranty-component',
{
    replace: true,

    props: ['purchase', 'warranty'], 
    
    template: '#warranty-template',
    
    data : function()
    {
        return {

            now : moment(),  

            // Moved to computed
            //warranty_end_date: moment(this.purchase).add(this.warranty, 'years'),
            //warranty_end_date_formated: moment(this.purchase).add(this.warranty, 'years').format("dddd, MMMM Do YYYY"), 
            // --- Moved to computed
        }
    },
    
    ready: function()
    {
    
    },
    
    computed: 
    {
        warranty_end_date: function()
        {
            return moment(this.purchase).add(this.warranty, 'years') ; 
        }, 

        warranty_end_date_formated: function(){
            return moment(this.purchase).add(this.warranty, 'years').format("dddd, MMMM Do YYYY") ; 
        }, 


        time_percent : function()
        {
            // % of usage
            var perc = Math.round((this.days_expired * 100) / this.days_all) ; 

            if( perc > 100 )
                return 100; 
            else    
                return perc ; 
        }, 

        days_all : function()
        {
            var a = this.purchase ; 
            var b = this.warranty_end_date ; 

            return b.diff(a, 'days') ;
        }, 

        /*days_left: function()
        {
            var a = this.now ; 
            var b = this.warranty_end_date ; 

            return b.diff(a, 'days') ; 
        },*/

        time_left: function()
        {
            return new moment().to(moment(this.warranty_end_date));
        },

        days_expired: function()
        {
            var a = this.purchase ; 
            var b = this.now ; 

            return b.diff(a, 'days') ; 
        }

    }, 
    
    methods : 
    { 
        
    }

});