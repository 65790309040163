// -------------------------
// Pagination
// -------------------------

Vue.component('pagination-component', {
    
    replace: true,
    
    template: '#pagination-template',
    
    ready: function ()
    {
        
    },
    
    data: function ()
    {
        return {
            itemsPerPage: 25 , 
            offsetIndex : 0 ,
            sitesInPagination : 4 , // pages per site from current page
            
            // computed : 
            // ---------------
            // totalPages
            // CurrentPage              
        }
    },
    
    computed : 
    {
        totalPages: function() 
        {
            return Math.ceil( this.$parent.items.length / this.itemsPerPage ) ; 
        },  
        currentPage: function ()
        {
            return this.offsetIndex + 1 ; 
        }
    }, 
    
    methods :
    {
        paginate: function () // init function 
        {          
            this.offsetIndex = 0 ; 
            this.paginateHandler() ; 
        }, 
        
        paginateHandler : function()
        {
            var offset = this.offsetIndex * this.itemsPerPage ;
            // console.log(offset);
            this.$parent.itemsPaginate = this.$parent.items.slice( offset, offset + this.itemsPerPage )  ; 
        },
        
        setPage: function(offsetIndex)
        {
            this.offsetIndex = offsetIndex ; 
            this.paginateHandler() ;             
        }, 
        
        prevPage: function()
        {
            if(this.offsetIndex > 0)
            {
                this.offsetIndex -- ; 
                this.paginateHandler() ; 
            }  
        }, 
        
        nextPage: function()
        {
            if(this.offsetIndex < this.totalPages - 1)
            {
                this.offsetIndex ++ ; 
                this.paginateHandler() ; 
            }
        }
        
    }
});