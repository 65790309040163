// -----------------------------------------
// Select fleet Component
// -----------------------------------------

Vue.component('select-set', {

    replace: true,  

    template: '#select-set-template' ,

    props : ['model', 'field'],
      
    ready : function()
    {
  
        this.loadItems();  
        
        // set current tags if passed from Controller - JSdata.tags

        if (typeof JSdata != 'undefined') 
        {
            this.selectedItems = JSdata.set ;   
        }
        
    }, 

    data: function () 
    {    
        return {  
             items :  null,
             selectedItems : [],
             selectedItemsInputFormat : null, 
             loading : 0
        }
    },
    
    computed : {
        
        selectedItemsInputFormat : function()
        {
            var tempArray = [] ;  

            for( index in this.selectedItems )
            {
                tempArray.push( this.selectedItems[index].id ) ; 
            }
            
            return _.uniq(tempArray) ;    
        }
        
    }, 
      
    methods : 
    {
        
        loadItems: function()
        {
            var self = this ; 

            // preloader 
            this.loading = 1 ; 

            // Ajax Call  
            self.$http.get( host + this.model ) 
            
            .success(function (data, status, request) 
            {    
                // preloader 
                self.loading = 0 ; 
        
                self.items = data ;
            })

            .error(function (data, status, request) {

                // preloader 
                self.loading = 0 ; 

                app.handleHttpError(status);
                
            })
            
        },
        
        addItemToSelected: function(item)
        {
            this.selectedItems.push(item) ;
        },
        
        removeItemFromSelected: function(item)
        {
            // find node 
            var node = _.filter(this.selectedItems, { id: item.id });
            // remove from array
            this.selectedItems.$remove(node[0]); 
        }, 
        
        selectAll : function()
        {
            this.removeAll() ; 
            
            for( index in this.items )
            {
                this.selectedItems.push( this.items[index]) ; 
            } 
        }, 
        
        removeAll : function ()
        {
            this.selectedItems = [] ; 
        }
        
        
        
    }
}); 


// -----------------------------------------
// Select fleet Item  Component
// -----------------------------------------

Vue.component('select-set-item', {

    replace: true,  

    template: '#select-item-template' ,
    
    data: function () 
    {    
        return {  
            selected : false, 
        }
    },
    
    computed: {
       
       selected : function()
       {
          if ( _.findIndex( this.$parent.selectedItems , { id: this.item.id } ) != -1 )
          {
              return true ; 
          }
          else 
          {
              return false; 
          }
       }, 

       thumbnail: function()
       {
           return host_storage + '/' + this.item.thumbnail.path ; 
       }, 

       field: function()
       {
            return this.item[this.$parent.field] ; 
       }
                
    }, 
      
    methods : 
    {
        
        toggleSelect: function()
        { 
            
           ( this.selected = !this.selected ) ? this.$parent.addItemToSelected( this.item ) : this.$parent.removeItemFromSelected(this.item) ;
        }
           
    }
}); 

