// ------------------------------------------------------
// Component list
// ------------------------------------------------------

Vue.component('serials-list-component',
{
    replace: true,
    
    template: '#serials-template',
    
    data : function()
    {
        return {
            items : null ,
            // itemsPaginate : null , 
            searchValue : '', 

            page: 1,
            pagination: {
                perpage: null, 
                curpage: null,
                totalpages: null, 
                from: null, 
                to: null, 
                total: null,
            },
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
            this.page = 1;
            this.getAjaxCall() ;
            this.$.paginationComponent.resetPaginationIndex()
        }, 

        searchModel: function () 
        {
            this.page = 1;
            this.getAjaxCall() ;
            this.$.paginationComponent.resetPaginationIndex()
        }, 

        page: function () 
        {
            this.getAjaxCall() ;
        }, 
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            // Ajax Call  
            this.$http.get( host + '/serial-numbers?q=' + this.searchValue + '&page=' + this.page )
            
            .success(function (data, status, request) 
            {    
                self.items = data.data;
                self.pagination.perpage = data.per_page; 
                self.pagination.curpage = data.current_page; 
                self.pagination.totalpages = data.last_page; 
                self.pagination.from = data.from; 
                self.pagination.to = data.to; 
                self.pagination.total = data.total; 
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        },

        resetFilters: function () {

            this.searchValue = ''; 
        }
    }

});

// ------------------------------------------------------
// Item Row Component
// ------------------------------------------------------

Vue.component('serial-itemrow-component',
{
    
    template: '#serial-itemrow-template' ,
    
    data : function()
    {
        return {
            item : null,

            updated_serial : null, 
            updated_date : null, 

            serial_edit_mode : false , 
            date_edit_mode : false , 
        }
    },

    ready: function()
    {
        this.updated_serial = this.item.serial_number ; 
        this.updated_date = this.item.purchase_date ;
    },
    
    computed: {

        created: function(){
            return moment(this.item.registration.created_at).format('Do MMM YY') ; 
        },

        purchased: function(){
            return moment(this.item.purchase_date).format('Do MMM YY') ; 
        }
    }, 
    
    methods : 
    { 

        // Serials 

        validateSerialNumberUpdate: function()
        {
            if( this.updated_serial === this.item.serial_number)
            {
                app.viewMessage({
                    'message_type':  'info', 
                    'message_title': 'Info', 
                    'message' 	:    'The serial number <b>' + this.updated_serial + '</b> has not been changed!',
                    'timeout':       2000
                }) ;
                
                this.exitSerialEditMode();
                return false;
            }

            if(this.updated_serial.length < 6)
            {
                app.viewMessage({
                    'message_type':  'error', 
                    'message_title': 'Warning', 
                    'message' 	:    'The serial number seems to be incorrect',
                    'timeout':       2000
                }) ;

                return false;
            }

            return true ; 
        }, 

        exitSerialEditMode: function()
        {
            this.serial_edit_mode = false ; 
            this.updated_serial = this.item.serial_number; 
        },

        updateSerialNumber: function()
        {
            if( !this.validateSerialNumberUpdate() ) { return ; } 

            self = this ;
            app_v.$.confirmation.show('Update Serial Number : '+ this.item.serial_number , 'Do you want to update the serial number to : ' + this.updated_serial + ' ?', 'Update', 'success') ;
            
            $('#confirmSuccess').off('click').on('click', function() {

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  
                
                self.$http.post( host + '/serial-numbers/' + self.item.id + '/update', { _token: csrf_token, serial_number: self.updated_serial  } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.item.serial_number = data.serial_number ; 

                    // after complete
                    self.serial_edit_mode = false ; 

                    app.viewMessage({
                        'message_type':  'success', 
                        'message_title': 'Success', 
                        'message' 	:    'The serial number was successfully updated',
                        'timeout':       2000
                    }) ; 
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;

                })
            }); 
        }, 

        // Purchase Date 

        validateDateUpdate: function()
        {
            if( this.updated_date === this.item.purchase_date)
            {
                app.viewMessage({
                    'message_type':  'info', 
                    'message_title': 'Info', 
                    'message' 	:    'The purchse date <b>' + this.updated_date + '</b> has not been changed!',
                    'timeout':       2000
                }) ;
                
                this.exitDateEditMode();
                return false;
            }

            if(this.updated_date.length < 6)
            {
                app.viewMessage({
                    'message_type':  'error', 
                    'message_title': 'Warning', 
                    'message' 	:    'The purchase date seems to be incorrect',
                    'timeout':       2000
                }) ;

                return false;
            }

            return true ; 
        }, 

        enterDateEditMode: function()
        {
            this.date_edit_mode = true ; 

            // Mask fields

            $('.date-mask').mask('y000-m0-d0', { 
                placeholder: "YYYY-MM-DD",
                'translation': {
                    y: {pattern: /[1-2]/},
                    m: {pattern: /[0-1]/},
                    d: {pattern: /[1-3]/}
                }
            });

            // Set Date Picker on fields 
            
            setTimeout(function(){
                $('.datepicker').datepicker({
                    format: "yyyy-mm-dd",
                    autoclose: true
                }) ;
            },1);
        },

        exitDateEditMode: function()
        {
            this.date_edit_mode = false ; 
            this.updated_date = this.item.purchase_date ; 
        },

        updatePurchaseDate: function()
        {
            if( !this.validateDateUpdate() ) { return ; } 

            self = this ; 

            app_v.$.confirmation.show('Update Purchase Date', 'Do you want to update the purchase date to : ' + this.updated_date+ ' ?', 'Update', 'success') ;
            
            $('#confirmSuccess').off('click').on('click', function() {

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  
                
                self.$http.post( host + '/serial-numbers/' + self.item.id + '/update', { _token: csrf_token, purchase_date: self.updated_date  } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.item.purchase_date = data.purchase_date ; 

                    // after complete
                    self.date_edit_mode = false ; 

                    app.viewMessage({
                        'message_type':  'success', 
                        'message_title': 'Success', 
                        'message' 	:    'The serial number was successfully updated',
                        'timeout':       2000
                    }) ; 
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;

                })
            }); 
            
        }, 

    }

});