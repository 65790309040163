
// -----------------------------------------
// Service Requests List Component
// -----------------------------------------

Vue.component('service-requests-list-component',
{
    replace: true,

    props : ['type'] , 
    
    template: '#service-requests-list-template',
    
    data : function()
    {
        return {
            items : null ,
            // itemsPaginate : null , 
            searchValue : '', 
            status : '', 

            page: 1,
            pagination: {
                perpage: null, 
                curpage: null,
                totalpages: null, 
                from: null, 
                to: null, 
                total: null,
            },
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
            this.page = 1;
            this.getAjaxCall() ;
            this.$.paginationComponent.resetPaginationIndex()
        }, 

        status: function () 
        {
            this.page = 1;
            this.getAjaxCall() ;
            this.$.paginationComponent.resetPaginationIndex()
        }, 

        page: function () 
        {
            this.getAjaxCall() ;
        }, 
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 

            var address = '' ; 
            if(this.type == 'all')
                address = '/service-requests' ; 
            else if (this.type == 'user')
                address = '/service-requests' ; 
            
            // Ajax Call  
            this.$http.get( host + address + '?q=' + this.searchValue + '&status=' + this.status + '&page=' + this.page)
            
            .success(function (data, status, request) 
            {    
                console.log(data.data);
                self.items = data.data;
                self.pagination.perpage = data.per_page; 
                self.pagination.curpage = data.current_page; 
                self.pagination.totalpages = data.last_page; 
                self.pagination.from = data.from; 
                self.pagination.to = data.to; 
                self.pagination.total = data.total;

                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        },

        resetFilters: function () {

            this.searchValue = ''; 
            this.status = '';
        }
    }

});

// ------------------------------------------------------
// Item Row Component
// ------------------------------------------------------

Vue.component('service-requests-itemrow-component',
{
    
    template: '#service-requests-itemrow-template' ,
    
    ready: function()
    {

    },
    
    data : function()
    {
        return {
            item : null , 
            type : this.$parent.type , 
        }
    },
    
    computed: {

        created: function(){
            return moment(this.item.created_at).format('Do MMM YY')  ; 
        }

    }, 
    
    methods : 
    { 
        deleteItem: function()
        {
            self = this ; 

            app_v.$.confirmation.show('Usunięcie zgłoszenia', 'Czy potwierdzasz usunięcie zgłoszenia serwisowego?', 'Usuń', 'danger');
            
            $('#confirmSuccess').off('click').on('click', function() {

                console.log(self.item) ;

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  

                self.$http.delete( host + '/service-requests/' + self.item.id , { _token: csrf_token } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.$parent.getAjaxCall('') ; 

                    app.viewMessage({
                        'message_type':  'success', 
                        'message_title': 'Success', 
                        'message' 	:    'Zgłoszenie zostalo poprawnie usunięte',
                        'timeout':       2000
                    }) ;
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;
                    
                })
            }); 
        }
    }

});