
// -------------------------
// main scope Vue.js  
// -------------------------

var app_v = new Vue({
    
    el: '#app',

    data: function() {

        return {

        }
    },

    events: {

        /**
         * resent event to all components  
         */

        'broadcast-to-all': function( event )
        {
            console.log( 'broadcasting event : ' + event.event + ' from $root instance.') ; 
            this.$broadcast( event.event , { data: event.data }  ); 
        }
    },
    
    components: {
        
        // Preloader Component 
        
        preloader: {
            
            data : function () {
                return {
                    visible: false, 
                }       
            },

            methods : {
                
                show : function(){
                    this.visible = true ;
                    $('.preload-content, .table').css('opacity', '.2 ');
                }, 
        
                hide: function(){
                    this.visible = false ; 
                    $('.preload-content, .table').css('opacity', '1');
                }
            },
            
            template: '<div v-show="visible" class="preloader rotation"></div>', 
        },    
    }

});


Vue.http.options.emulateHTTP = true;