;(function($,exports){
    
    app = {} ; 
    
    // -------------------------
    // el's
    // -------------------------
    var top_bar             = $('.top-bar') ; 
    var header_menu         = $('.top-menu');
    var sidebar_offcanvas   = $('.sidebar-offcanvas');  
    var sidebar_triger      = $('.sidebar-triger');
    var wrapper             = $('.wrapper') ; 
   
    // -------------------------
    // Var's
    // -------------------------
    
    var window_width ;
    var window_height ; 
    var content_off_screen_value = 1180 ; // wartość po jakiej content będzie wysuwany za ekran 
    var sidebar_offcanvas_active = false ; // czy wysunięty sidebar 
    
    var mobile = false ; 
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {	mobile = true ; }

    
    // -------------------------
    // Window Resize
    // -------------------------
    
    app.resize = function ()
    {
        window_width = $(window).width() ; 
        window_height = $(window).height() ; 
        
        // sidebarContent 
        $('.sidebar-content').height( window_height - 70 );
    };


    // -------------------------
    // Get Thumbnail
    // -------------------------

    app.get_thumb = function (path, size) {

        return host + '/image.php?image=' + path + '&width=' + size;
    }
    

    // -------------------------
    // Fast Click Prevent
    // -------------------------

    app.submitAddForm = function (event)
    {
        // Formularz musi mieć dodane id='addForm' oraz onsubmit="app.submitAddForm(event)"
        // Submit button musi mieć klasę class="single-click"

        var validate = $('#addForm').parsley() ; 
        if (validate.isValid())
        {
            $('.single-click').attr('disabled','disabled'); 
        } 
    }

    // -------------------------
    // Http error status Handler
    // -------------------------
    
    app.handleHttpError = function (status)
    {
        // Unauthorized
        if ( status == 401 ){
             window.location = host +'/login' ; 
             return;
        }
             
        // Server Error / token mismatch 
        if ( status == 500 ){
             window.location = host +'/' ; 
             return;
        }
    }

    // -------------------------
    // Info Window ( responce )
    // -------------------------
    
    app.viewMessage = function ( responce  )
    { 
        var timeout = 8000 ; 

        // Set Timeout 
        if (responce.timeout)
            timeout = responce.timeout ; 

        iziToast[responce.message_type]({
            class : 'warning' , 
            title: responce.message_title,
            message: responce.message,
            timeout :  timeout, 
            position: 'topCenter',
        });
    };
    
    // -------------------------
    // Sidebar 
    // -------------------------
    
    app.sidebar = function ()
    {
        sidebar_triger.click(function ()
        {
            return (sidebar_offcanvas_active = !sidebar_offcanvas_active) ? app.sidebarOffcanvasHandler('show') : app.sidebarOffcanvasHandler('hide');
        });
    };
    
    app.sidebarOffcanvasHandler = function (state)
    {
        switch(state)
        {
            case 'show' :
                
                TweenLite.to( sidebar_offcanvas , .7, { x:0 , ease:Expo.easeInOut });
                TweenLite.to( sidebar_triger , .7, { x : 220, ease:Expo.easeInOut , delay:.2 });
                TweenLite.to( top_bar , .7, { css:{paddingLeft: 20}, ease:Expo.easeInOut });
 
                $(sidebar_triger).addClass('triger-fixed');

                
                if (window_width >= content_off_screen_value){
                    TweenLite.to( wrapper , .8, { css:{marginLeft:"300px"} , ease:Expo.easeInOut });
                    TweenLite.to( wrapper , .4, { x:0 , ease:Expo.easeInOut }); 
                }else{
                    TweenLite.to( wrapper , .8, { x:300 , ease:Expo.easeInOut });
                    TweenLite.to( wrapper , .4, { css:{marginLeft:"0px"}, ease:Expo.easeInOut });
                }
                break; 
                
            case 'hide' :
                
                TweenLite.to( sidebar_offcanvas , .7, { x:-300, ease:Expo.easeInOut });
                TweenLite.to( sidebar_triger , .7, { x : 0, ease:Expo.easeInOut  });
                TweenLite.to( top_bar , .7, { css:{paddingLeft: 100}, ease:Expo.easeInOut });

                $(sidebar_triger).removeClass('triger-fixed');
                
                if (window_width >= content_off_screen_value ){
                    TweenLite.to( wrapper , .8, { css:{marginLeft:"0px"} , ease:Expo.easeInOut });
                    TweenLite.to( wrapper , .4, { x:0 , ease:Expo.easeInOut });
                }else{
                    TweenLite.to( wrapper , .8, { x:0 , ease:Expo.easeInOut });
                    TweenLite.to( wrapper , .4, { css:{marginLeft:"0px"} , ease:Expo.easeInOut });
                }
                break; 
        }      
    };


    // ----------------------------
    // Scrollers 
    // ----------------------------
    
    app.scrollbars = function ()
    {
        
        $(".sidebar-content").mCustomScrollbar({
            theme:"minimal-dark",
            axis:"y" , 
            scrollInertia: 400 , 
            scrollButtons:{
                enable:true 
            },
            advanced:{
                updateOnContentResize: true
            }
        });
    }
    
    // -------------------------
    // Url Helpers ( php.js )
    // -------------------------
    
    app.urlEncode = function (str) {

        str = (str + '')
          .toString().trim();
  
       return encodeURIComponent(str)
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/\*/g, '%2A')
            .replace(/%20/g, '+');
    }
    
    app.urlDecode = function(str) 
    {
        return decodeURIComponent((str + '')
        .replace(/%(?![\da-f]{2})/gi, function() {
          // PHP tolerates poorly formed escape sequences
          return '%25';
        })
        .replace(/\+/g, '%20'));
    }
   
    // -------------------------
    // Init App
    // -------------------------
    
    app.init = function ()
    {  
        // Load Functions
        var loadFunction ;
        while((loadFunction = loadqueue.shift())!= null) {
            app[loadFunction]();
        }

        app.sidebar();
        app.resize(); 
        app.scrollbars() ; 


        // Animations 
		
		TweenMax.staggerFromTo( '.animation', 1, {
                    opacity: 0,
                    x: "-=80"
                }, {
                    opacity: 1,
                    x: "0",
                    ease: Expo.easeOut
                }, 0.14) ;
    };
    
})(jQuery,window); 