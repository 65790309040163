// ------------------------------------------------------
// Notes Component list
// ------------------------------------------------------

Vue.component('notes-component',
{
    replace: true,
    
    template: '#notes-template',

    props: ['model', 'modelId'],

    events: {

        'addNote': function( data ) {

            this.add_note(data.data);  
        },

        'loadNotes': function() {

            this.getAjaxCall();  
        }
    },
    
    data : function()
    {
        return {
            items : null ,
            itemsPaginate : null ,
            searchValue : '',
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
            this.getAjaxCall() ;
        }, 
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show(); 
            
            // Ajax Call  
            this.$http.get( host + '/' + this.model +'/'+ this.modelId + '/notes?q=' + this.searchValue )
            
            .success(function (data, status, request) 
            {    
                self.items = data;

                // set pagination  
                self.$.paginationComponent.paginate() ;
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        }, 

        add_modal: function ()
        {
            // Sent event and broadcast from the root instance 

            this.$dispatch('broadcast-to-all', 
            { 
                event: 'addNoteModal' , 
                data: {
                    title: 'Dodaj treść notatki zgłoszenia',
                    item: null
                } 
            }) ;
        }, 

        add_note: function (data) 
        {

            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            //  Ajax Call  

            this.$http.post( host + '/' + this.model +'/'+ this.modelId + '/note',  { _token: csrf_token, body: data.body, is_private: data.is_private })
            
            .success(function (data, status, request) 
            {    
                self.getAjaxCall() ; 
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        }

    }

});

// ------------------------------------------------------
// Notes Item Row Component
// ------------------------------------------------------

Vue.component('note-item',
{
    
    template: '#note-item-template' ,
    
    ready: function()
    {
       this.user_role = auth_user_role ; // rola zalogowanego z index.php
    },
    
    data : function()
    {
        return {
            item : null , 
            item_notify_passed: null, 

            user_role: null , 
            hover: false , 
        }
    },
    
    computed: 
    {
        author: function(){

            if(auth_user_role == 'admin')
            {
                return true ; 
            }
            else if (auth_user == this.item.user.id) {
                return true ; 
            }

            return false ; 
        },

        created_at : function ()
        {
            return moment(this.item.created_at).format("HH:mm"); 
        }, 

        updated_at: function(){

            var created = moment(this.item.created_at) ; 
            var updated = moment(this.item.updated_at) ; 

            if ( updated.isAfter( created ) )
            {
                return updated.fromNow() ; 
            }
            else
            {
                return false ; 
            }
        },


        itemTimeLabel: function()
        {
            return moment(this.item.created_at).format("dddd, Do MMMM YYYY");
        }, 

        showItemTimeLabel: function ()
        {
            if( this.$index == 0 )
            {
                return true ; 
            }
            else 
            {
                var a = moment(this.$parent.items[this.$index - 1].created_at.substring(0,10)); // prev date 
                var b = moment(this.item.created_at.substring(0,10)); // current item date 
                
                if ( a.diff(b, 'days') )
                    return true ; 
                else
                    return false ; 
            }
        }, 
    }, 
    
    methods : 
    { 

        edit:function()
        {
            // Sent event and broadcast from the root instance 

            this.$dispatch('broadcast-to-all', 
            { 
                event: 'addNoteModal' , 
                data: {
                    title: 'Edytuj treść notatki zgłoszenia',
                    item: this.item 
                } 
            }) ;
        },

        delete : function()
        {

            var self = this ; 

            app_v.$.confirmation.show('Potwierdznie Usunięcia', 'Czy potwierdzasz usunięcie notatki ? ', 'Usuń', 'danger') ;

            $('#confirmSuccess').off('click').on('click', function() {

                app_v.$.confirmation.hide() ; 

                app_v.$.preloader.show()  ; 

                self.$http.delete( host + '/notes/'+self.item.id ,  { _token: csrf_token } )

                    .success(function (data, status, request) 
                    {   
                        app_v.$.preloader.hide()  ; 

                        app.viewMessage(data) ; 
 
                        // Sent event and broadcast from the root instance 

                        this.$dispatch('broadcast-to-all', 
                        { 
                            event: 'loadNotes' , 
                            data: {} 
                        }) ;
                    })

                    .error(function (data, status, request) {
                        
                        app.handleHttpError(status);
                    }) 
                
            }); 
        }, 
    }

});


// ------------------------------------------------------
// Add Note Component 
// ------------------------------------------------------

Vue.component('notes-modal',
{
    replace: true,
    
    template: '#notes-modal-template',

    events: {

        'addNoteModal': function( data )
        { 
            this.init(data.data.title, data.data.item);  
        }
    },
    
    data : function()
    {
        return {
            
            title: null,
            item: null, 

            edit_mode: false , // czy tryb edycji 

            user_role: auth_user_role , // rola zalogowanego z index.php

            is_private : false,

            submited: false , 
        }
    },
    
    ready: function()
    {

    },

    methods : {

        init: function( title, item )
        {
            this.reset() ; 

            this.title = title ; 
            this.item = item ; 
            
            if(item != null)
                this.setEditMode() ; 
            
            $('#notesModal').modal() ;
        },

        setEditMode: function()
        {
            this.edit_mode = true ; 
            this.setContent(this.item.body) ;
        }, 

        reset: function()
        {
            this.edit_mode = false ; 
            this.setContent('') ; 
            this.is_private  =  false ; 
            this.submited = false ; 
        }, 

        setContent: function( content )
        {
            tinyMCE.activeEditor.setContent( content , {format : 'raw'});
        }, 

        getContent: function()
        {
            return tinyMCE.activeEditor.getContent() ; 
        }, 


        setPrivate: function ()
        {
            this.is_private = !this.is_private ; 

            if(this.is_private)
                this.send_email = false ; 
        },

        submit: function()
        {
            if(!this.getContent().length)
            {
                return app.viewMessage({
                    'message_type'  : 'warning', 
                    'message_title' : 'Uwaga', 
                    'message' 	    : 'Należy podać treść notatki', 
                    'timeout'       : 2000
                }) ;
            }

            this.submited = true ; 

            $('#notesModal').modal('hide') ; 

            // Sent event and broadcast from the root instance 
            this.$dispatch('broadcast-to-all', 
            { 
                event: 'addNote' , 
                data: {
                    body: this.getContent(),
                    is_private: this.is_private 
                } 
            });
        }, 

        update: function ()
        {
            if(!this.getContent().length)
            {
                return app.viewMessage({
                    'message_type'  : 'warning', 
                    'message_title' : 'Uwaga', 
                    'message' 	    : 'Należy podać treść notatki', 
                    'timeout'       : 2000
                }) ;
            }

            this.submited = true ; 
            $('#notesModal').modal('hide') ; 

            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            // Ajax Call  
            this.$http.put( host + '/notes/'+this.item.id, { _token: csrf_token , body: this.getContent() } )
            
            .success(function (data, status, request) 
            {    
                // Sent event and broadcast from the root instance 

                this.$dispatch('broadcast-to-all', 
                { 
                    event: 'loadNotes' , 
                    data: {} 
                }) ;

                app.viewMessage(data) ; 
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);

            })
        }
    } 
});