// ------------------------------------------------------
// set Component list
// ------------------------------------------------------

Vue.component('set-list-component',
{
    replace: true,
    
    template: '#set-template',
    
    data : function()
    {
        return {
            items : null ,
            itemsPaginate : null , 
            searchValue : '',
            searchRole  : '', 
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
           this.getAjaxCall() ;
        }, 
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            // Ajax Call  
            this.$http.get( host + '/sets?q=' + this.searchValue  )
            
            .success(function (data, status, request) 
            {    
                self.items = data;
                
                // set pagination  
                self.$.paginationComponent.paginate() ;
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        }
    }

});

// ------------------------------------------------------
// Users Item Row Component
// ------------------------------------------------------

Vue.component('set-itemrow-component',
{
    
    template: '#set-itemrow-template' ,
    
    ready: function()
    {
      
    },
    
    data : function()
    {
        return {
            item : null 
        }
    },
     
    computed: {
        thumbnail: function(){
            return app.get_thumb(host_storage + '/' + this.item.thumbnail.path, 260) ; 
        }, 

        created: function(){
            return moment(this.item.created_at).format('Do MMM YY') ; 
        }, 

        updated: function(){
            return moment(this.item.updated_at).format('Do MMM YY') ; 
        }
    }, 
    
    methods : 
    { 
        delete: function()
        {
            var self = this ; 

            app_v.$.confirmation.show('Potwierdznie usunięcia', 'Czy potwierdzasz usunięcia zestawu ('+this.item.model+') wraz z załcznikami ?' , 'Tak usuń', 'danger') ;
            $('#confirmSuccess').off('click').on('click', function() {

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  
                self.$http.delete( host + '/sets/' + self.item.id , { _token: csrf_token } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.$parent.getAjaxCall('') ; 

                    app.viewMessage({
                        'message_type'  : 'success', 
                        'message_title' : 'Powodzenie', 
                        'message' 	    : 'Poprawnie usunięto zestaw', 
                        'timeout'       : 2000
                    }) ;
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;
                    
                })
            });     
        }
    }

});