// ------------------------------------------------------
// messages Component list
// ------------------------------------------------------

Vue.component('messages-component',
{
    replace: true,
    
    template: '#messages-template',

    props : ['type'],
    
    data : function()
    {
        return {
            items : null ,
            itemsPaginate : null ,

            label_filters : [

                {label: 'primary', text: 'information', selected:    false },
                {label: 'warning', text: 'warranty', selected: false }, 
                {label: 'danger', text: 'alert', selected:  false }, 
                {label: 'success', text: 'success', selected:  false }, 
                
            ], 

            label_filter_url_string: null,  
            searchValue : '',
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
           this.getAjaxCall() ;
        },   
    }, 

    computed : 
    {
        label_filter_url_string: function ()
        {
            var string = '' ; 
            var selected_labels = [] ; 

            _.forEach(this.label_filters, function(filter) 
            {
                if (filter.selected)
                {
                    selected_labels.push(filter.label) ; 
                }
            });

            if(selected_labels.length)
            {
                string += '&label=' + selected_labels.toString();
            } 
            else
            {
                string = '' ;
            }

            return string ;
        }
    } , 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 

            var address = '';
            (this.type == 'all') ? address = '/messages' : address = '/my-messages' ;   
            
            // Ajax Call  
            this.$http.get( host + address + '?q=' + this.searchValue +  this.label_filter_url_string )
            
            .success(function (data, status, request) 
            {    
                self.items = data;

                // set pagination  
                self.$.paginationComponent.paginate();
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        }, 

        toggleLabelFilters: function(index)
        {
            this.label_filters[index].selected = !this.label_filters[index].selected  ; 
            this.getAjaxCall() ;
        }

    }

});

// ------------------------------------------------------
// Notes Item Row Component
// ------------------------------------------------------

Vue.component('message-item',
{
    
    template: '#message-item-template' ,
    
    ready: function()
    {
       
    },
    
    data : function()
    {
        return {
            item : null , 
            type : this.$parent.type , 
         
        }
    },
     
    computed: 
    {
        date : function ()
        {
            return moment(this.item.created_at).fromNow();
        }, 

        itemTimeLabel: function()
        {
            return moment(this.item.created_at).format("dddd, Do MMMM YYYY");
        }, 

        showItemTimeLabel: function ()
        {
            if( this.$index == 0 )
            {
                 return true ; 
            }
            else 
            {
                var a = moment(this.$parent.items[this.$index - 1].created_at); // prev date 
                var b = moment(this.item.created_at); // current item date 
                
                if ( a.diff(b, 'days') )
                    return true ; 
                else
                    return false ; 
            }
        }, 

    }, 
    
    methods : 
    { 
        markAsRead: function()
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            // Ajax Call  
            this.$http.post( host + '/messages/' + this.item.id + '/read', { _token: csrf_token } )
            
            .success(function (data, status, request) 
            {    
                self.item.pivot.readed = 1;
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                // app.handleHttpError(status);
                
            })
        }, 

        showRelatedUsers: function()
        {

        },

        delete: function()
        {
            var self = this ; 

            app_v.$.confirmation.show('Delete message', 'Will you confirm the deletion of the message: ' + self.item.title + '<br/>Related users will no longer see this message.', 'Delete', 'danger') ;
            $('#confirmSuccess').off('click').on('click', function() {

                app_v.$.confirmation.hide() ; 
                app_v.$.preloader.show()  ; 
            
                // Ajax Call  

                self.$http.delete( host + '/messages/' + self.item.id , { _token: csrf_token } ) 
                
                .success(function (data, status, request) 
                {    
                    app_v.$.preloader.hide()  ; 

                    self.$parent.getAjaxCall('') ; 

                    app.viewMessage({
                        'message_type':  'success', 
                        'message_title': 'Success', 
                        'message' 	:    'The message was successfully deleted',
                        'timeout':       2000
                    }) ;
                })

                .error(function (data, status, request) {

                    app.handleHttpError(status) ;
                    
                })
            }); 
        }
    }

});




// ------------------------------------------------------
// Add Message Component
// ------------------------------------------------------

Vue.component('add-message',
{
    
    template: '#add-message-template' ,
    
    ready: function()
    {
       
    },
    
    data : function()
    {
        return {
            item : null , 

            label_filters : [

                {label: 'primary', text: 'information', selected:  true }, 
                {label: 'warning', text: 'warranty', selected: false }, 
                {label: 'danger', text: 'alert', selected:  false }, 
                {label: 'success', text: 'success', selected:  false }, 
                
            ], 

            send_options : [

                {label: 'all', text: 'send to users', model: '', field: '',  selected:  true }, 
                {label: 'users', text: 'specify users', model: '/users', field: 'name', selected:  false }, 
                {label: 'sets', text: 'specify sets', model: '/sets', field: 'model', selected: false }, 
                {label: 'products', text: 'specify products', model: '/products', field: 'model', selected:  false }, 
                {label: 'serials', text: 'specify serial numbers', model: '/serial-numbers', field: 'serial_number', selected:  false },
            
            ], 

            selected_label:  'primary', 
            selected_option: 'all', 
            selected_model:  '', 
            selected_field:  ''
         
        }
    },
     
    computed: 
    {

    }, 
    
    methods : 
    { 
        toggleLabelFilters: function(index)
        {
            var self = this ; 

            _.forEach(this.label_filters , function(filter)
            {
                filter.selected = false ; 
            }) ; 

            // Select label 
            this.label_filters[index].selected = true ;  

            // Set Label 
            this.selected_label = this.label_filters[index].label ; 
        }, 

        toggleSendOptions: function(index)
        {
            var self = this ; 

            _.forEach(this.send_options , function(option)
            {
                option.selected = false ; 
            }) ; 

            // Select label 
            this.send_options[index].selected = true ;  

            // Set Label 
            this.selected_option = this.send_options[index].label ; 
            this.selected_model  = this.send_options[index].model ; 
            this.selected_field  = this.send_options[index].field ; 

            // Refference 

            if (this.selected_option != 'all')
            {
                setTimeout(function(){
                    self.$.selectSet.removeAll();
                    self.$.selectSet.loadItems();
                }, 1);
            }  
            
        }

    }

});