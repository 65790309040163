// -----------------------------------------
// Confirmation Component
// -----------------------------------------

Vue.component('confirmation', {

    replace: true,  

    template: '#confirmation-template' ,
      
    data: function () 
    {    
        return {
            title : '',
            body : '',
            action_name : '',
            action_style  : ''
        }
    }, 
    
    methods : {
        
        show: function ( title, body, action, style )
        {
            this.title = title ;
            this.body =  body ;
            this.action_name = action ;
            this.action_style =  style  ;
            
            $('#confirmation').modal() ; 
        }, 
        
        hide : function ()
        {
            $('#confirmation').modal('hide') ; 
        }
        
    }
    

}); 