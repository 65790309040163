// ------------------------------------------------------
// Users Component list
// ------------------------------------------------------

Vue.component('users-list-component',
{
    replace: true,
    
    template: '#users-template',
    
    data : function()
    {
        return {
            items : null ,
            itemsPaginate : null , 
            searchValue : '',
            searchRole  : '', 
        }
    },
    
    ready: function()
    {
        var self = this ; 
        
        $(document).ready(function()
        {
            self.getAjaxCall('') ;
        });
    },
    
    watch: 
    {
        searchValue: function () 
        {
           this.getAjaxCall() ;
        }, 

        searchRole: function () 
        {
           this.getAjaxCall() ;
        }, 
        
    }, 
    
    methods : 
    { 
        getAjaxCall : function( query )
        {
            var self = this ; 
            
            // preloader 
            app_v.$.preloader.show()  ; 
            
            // Ajax Call  
            this.$http.get( host + '/users?q=' + this.searchValue + '&role=' + this.searchRole )
            
            .success(function (data, status, request) 
            {    
                self.items = data;
                
                // set pagination  
                self.$.paginationComponent.paginate() ;
                
                // preloader 
                app_v.$.preloader.hide()  ; 
            })

            .error(function (data, status, request) {

                app.handleHttpError(status);
                
            })
        }
    }

});

// ------------------------------------------------------
// Users Item Row Component
// ------------------------------------------------------

Vue.component('users-itemrow-component',
{
    
    template: '#users-itemrow-template' ,
    
    ready: function()
    {
      
    },
    
    data : function()
    {
        return {
            item : null 
        }
    },
     
    computed: {
         
        role_name : function(){

            if(this.item.role == 'user'){
                return 'User' ; 
            }

            if(this.item.role == 'employee'){
                return 'Employee' ; 
            }

            if(this.item.id == 1 && this.item.role == 'admin'){
                return 'SuperAdmin' ; 
            }
            
            if(this.item.role == 'admin'){
                return 'Admin' ; 
            }   
        }
    }, 
    
    methods : 
    { 
       
    }

});